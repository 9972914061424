module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"./src/assets/images/favicon.png","name":"Cosentyx - Novartis","short_name":"Cosentyx","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"9c9fe078218238fbec3976b172d6a9cf"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PKSVS2M","includeInDevelopment":true,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../node_modules/@nvs/gatsby-theme-leap-core/gatsby-browser.tsx'),
      options: {"plugins":[],"brand":"Cosentyx","GTMId":"GTM-PKSVS2M","manifestName":"Cosentyx - Novartis","manifestShortName":"Cosentyx"},
    },{
      plugin: require('../node_modules/@nvs/gatsby-theme-leap-survey/gatsby-browser.tsx'),
      options: {"plugins":[],"brand":"Cosentyx","endpoint":"https://q1s3ur35u1.execute-api.us-east-1.amazonaws.com","region":"us-east-1","identityPoolId":"us-east-1:0a17fd3e-1543-4d36-99b7-2c0b69530409","tab":{"show":true,"path":"/"}},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
